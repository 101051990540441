<template>
  <div class="calendar-container">
    <div class="calendar-header">
      <button @click="previousMonth">&lt;</button>
      <h3>{{ currentMonthName }} {{ currentYear }}</h3>
      <button @click="nextMonth">&gt;</button>
    </div>
    
    <div class="calendar-grid">
      <div class="calendar-day-header" v-for="day in weekDays" :key="day">{{ day }}</div>
      
      <div 
        v-for="(day, index) in calendarDays" 
        :key="index"
        class="calendar-day"
        :class="{ 
          'current-month': day.currentMonth, 
          'today': day.isToday,
          'has-schedule': day.hasSchedule
        }"
      >
        <div class="day-number">{{ day.day }}</div>
        <div v-if="day.hasSchedule" class="schedule-info">
          <div v-for="(slot, slotIndex) in day.scheduleSlots" :key="slotIndex" class="schedule-time">
            {{ slot.startTime }} - {{ slot.endTime }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import workforceService from '@/services/workforceService';

export default {
  name: 'WorkforceCalendar',
  data() {
    return {
      currentDate: new Date(),
      currentMonth: new Date().getMonth(),
      currentYear: new Date().getFullYear(),
      weekDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      userSchedule: [],
      loading: false,
      error: null
    }
  },
  computed: {
    currentMonthName() {
      return new Date(this.currentYear, this.currentMonth, 1).toLocaleString('default', { month: 'long' });
    },
    calendarDays() {
      const days = [];
      
      // Get the first day of the month
      const firstDayOfMonth = new Date(this.currentYear, this.currentMonth, 1);
      const lastDayOfMonth = new Date(this.currentYear, this.currentMonth + 1, 0);
      
      // Get the day of the week for the first day (0-6, where 0 is Sunday)
      const firstDayOfWeek = firstDayOfMonth.getDay();
      
      // Add days from previous month to fill the first week
      const prevMonthLastDay = new Date(this.currentYear, this.currentMonth, 0).getDate();
      for (let i = firstDayOfWeek - 1; i >= 0; i--) {
        const day = prevMonthLastDay - i;
        days.push({
          day,
          currentMonth: false,
          isToday: false,
          hasSchedule: false
        });
      }
      
      // Add days of the current month
      for (let day = 1; day <= lastDayOfMonth.getDate(); day++) {
        const date = new Date(this.currentYear, this.currentMonth, day);
        const isToday = this.isToday(date);
        
        // Check if this day has a schedule
        const scheduleForDay = this.getScheduleForDay(day);
        
        days.push({
          day,
          currentMonth: true,
          isToday,
          hasSchedule: scheduleForDay !== null,
          scheduleSlots: scheduleForDay ? (scheduleForDay.timeSlots || [{
            startTime: scheduleForDay.startTime || '00:00',
            endTime: scheduleForDay.endTime || '00:00'
          }]) : []
        });
      }
      
      // Add days from next month to fill the remaining cells
      const totalDaysAdded = days.length;
      const remainingCells = 42 - totalDaysAdded; // 6 rows x 7 days
      
      for (let day = 1; day <= remainingCells; day++) {
        days.push({
          day,
          currentMonth: false,
          isToday: false,
          hasSchedule: false
        });
      }
      
      return days;
    }
  },
  methods: {
    previousMonth() {
      if (this.currentMonth === 0) {
        this.currentMonth = 11;
        this.currentYear--;
      } else {
        this.currentMonth--;
      }
      this.fetchUserSchedule();
    },
    nextMonth() {
      if (this.currentMonth === 11) {
        this.currentMonth = 0;
        this.currentYear++;
      } else {
        this.currentMonth++;
      }
      this.fetchUserSchedule();
    },
    isToday(date) {
      const today = new Date();
      return date.getDate() === today.getDate() &&
             date.getMonth() === today.getMonth() &&
             date.getFullYear() === today.getFullYear();
    },
    getScheduleForDay(day) {
      return this.userSchedule.find(schedule => {
        const scheduleDate = schedule.date.toDate();
        return scheduleDate.getDate() === day &&
               scheduleDate.getMonth() === this.currentMonth &&
               scheduleDate.getFullYear() === this.currentYear;
      }) || null;
    },
    async fetchUserSchedule() {
      this.loading = true;
      this.error = null;
      
      try {
        this.userSchedule = await workforceService.getUserSchedule(this.currentMonth, this.currentYear);
      } catch (error) {
        console.error('Error fetching user schedule:', error);
        this.error = 'Failed to load schedule. Please try again.';
      } finally {
        this.loading = false;
      }
    }
  },
  mounted() {
    this.fetchUserSchedule();
  }
}
</script>

<style scoped>
.calendar-container {
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background-color: #f5f5f5;
}

.calendar-header button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  padding: 5px 10px;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1px;
  background-color: #e0e0e0;
}

.calendar-day-header {
  background-color: #f9f9f9;
  padding: 10px;
  text-align: center;
  font-weight: bold;
}

.calendar-day {
  background-color: white;
  min-height: 80px;
  padding: 5px;
  position: relative;
}

.calendar-day:not(.current-month) {
  opacity: 0.5;
}

.day-number {
  position: absolute;
  top: 5px;
  right: 5px;
  font-weight: bold;
}

.today {
  background-color: #e8f5e9;
}

.has-schedule {
  background-color: #e3f2fd;
}

.schedule-info {
  margin-top: 25px;
  font-size: 0.8em;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.schedule-time {
  background-color: #bbdefb;
  padding: 3px 6px;
  border-radius: 4px;
  display: inline-block;
}
</style> 