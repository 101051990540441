import { render, staticRenderFns } from "./WorkforceCalendar.vue?vue&type=template&id=4eb5bf3e&scoped=true"
import script from "./WorkforceCalendar.vue?vue&type=script&lang=js"
export * from "./WorkforceCalendar.vue?vue&type=script&lang=js"
import style0 from "./WorkforceCalendar.vue?vue&type=style&index=0&id=4eb5bf3e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4eb5bf3e",
  null
  
)

export default component.exports