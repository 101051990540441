<template>
  <v-container v-bind:class="{ 'pa-0': $vuetify.breakpoint.smAndDown }">
     <v-card class="rounded-lg overflow-hidden" elevation="2">
      <v-card-title>
        <v-icon left color="primary">mdi-account-group-outline</v-icon>
        Fuerza de trabajo
      </v-card-title>
    
    <v-alert v-if="error" type="error" dismissible>
      {{ error }}
    </v-alert>
    
    <div class="user-actions" v-if="!isAdmin">
      <v-skeleton-loader v-if="loading" type="card" class="mb-4"></v-skeleton-loader>
      
      <div v-else>
        <div class="status-card">
          <h2>Current Status: {{ currentStatus }}</h2>
          <p>{{ statusMessage }}</p>
        </div>
        
        <div class="action-buttons">
          <v-btn 
            @click="checkIn" 
            :disabled="currentStatus !== 'Not Checked In' || loading || noMoreCheckInsAllowed"
            :loading="actionLoading === 'checkIn'"
            color="success"
            class="action-button">
            Check In
          </v-btn>
          
          <v-btn 
            @click="goToLunch" 
            :disabled="currentStatus !== 'Working' || loading"
            :loading="actionLoading === 'lunch'"
            color="warning"
            class="action-button">
            Go to Lunch
          </v-btn>
          
          <v-btn 
            @click="resumeWork" 
            :disabled="currentStatus !== 'On Lunch' || loading"
            :loading="actionLoading === 'resume'"
            color="info"
            class="action-button">
            Resume Work
          </v-btn>
          
          <v-btn 
            @click="checkOut" 
            :disabled="currentStatus !== 'Working' || loading"
            :loading="actionLoading === 'checkOut'"
            color="error"
            class="action-button">
            Check Out
          </v-btn>
        </div>
      </div>
    </div>
    
    <div class="admin-panel" v-if="isAdmin">
      <WorkforceAdminPanel @error="handleError" />
    </div>
    
    <div class="user-schedule" v-if="!isAdmin">
      <h2>My Schedule</h2>
      <WorkforceCalendar />
    </div>
    
    <div class="work-summary" v-if="!isAdmin">
      <h2>Work Hours Summary</h2>
      <WorkforceSummary />
    </div>
  </v-card>
  </v-container>


 
</template>

<script>
import WorkforceAdminPanel from './WorkforceAdminPanel.vue';
import WorkforceCalendar from './WorkforceCalendar.vue';
import WorkforceSummary from './WorkforceSummary.vue';
import workforceService from '@/services/workforceService';

import { Timestamp } from 'firebase/firestore';
import moment from 'moment';

export default {
  name: 'WorkforceManagement',
  components: {
    WorkforceAdminPanel,
    WorkforceCalendar,
    WorkforceSummary
  },
  data() {
    return {
      currentStatus: 'Not Checked In', // Possible values: 'Not Checked In', 'Working', 'On Lunch', 'Checked Out'
      statusMessage: 'You have not checked in yet.',
      checkInTime: null,
      checkOutTime: null,
      lunchStartTime: null,
      lunchEndTime: null,
      isAdmin: false, // This should be determined based on user role
      loading: true,
      error: null,
      actionLoading: null, // 'checkIn', 'lunch', 'resume', 'checkOut'
      noMoreCheckInsAllowed: false,
      totalScheduledShifts: 0,
      completedShifts: 0
    }
  },
  methods: {
    async checkIn() {
      try {
        this.actionLoading = 'checkIn';
        this.error = null;
        
        // Get current location
        const position = await this.getCurrentPosition();
        
        // Record check-in
        try {
          const result = await workforceService.recordCheckIn(position);
          
          // Update component state
          this.checkInTime = new Date();
          this.currentStatus = 'Working';
          this.statusMessage = `Checked in at ${this.formatTime(this.checkInTime)}`;
        } catch (error) {
          // If this is an early check-in warning, show it but allow override
          if (error.message && error.message.includes("minutes early")) {
            const shouldContinue = confirm(`${error.message}\n\nDo you want to check in anyway?`);
            
            if (shouldContinue) {
              // Force check-in by setting an override flag
              const result = await workforceService.recordCheckIn(position, null, true);
              
              // Update component state
              this.checkInTime = new Date();
              this.currentStatus = 'Working';
              this.statusMessage = `Checked in at ${this.formatTime(this.checkInTime)} (Early check-in)`;
            } else {
              throw new Error("Check-in cancelled");
            }
          } else {
            // For other errors, just rethrow
            throw error;
          }
        }
        
        this.actionLoading = null;
      } catch (error) {
        console.error('Check-in failed:', error);
        this.error = error.message || 'Failed to check in. Please try again.';
        this.actionLoading = null;
      }
    },
    
    async goToLunch() {
      try {
        this.actionLoading = 'lunch';
        this.error = null;
        
        const result = await workforceService.startLunch();
        
        this.lunchStartTime = result.lunchStartTime.toDate();
        this.currentStatus = 'On Lunch';
        this.statusMessage = `Lunch started at ${this.formatTime(this.lunchStartTime)}`;
        
        this.actionLoading = null;
      } catch (error) {
        console.error('Start lunch failed:', error);
        this.error = error.message || 'Failed to start lunch. Please try again.';
        this.actionLoading = null;
      }
    },
    
    async resumeWork() {
      try {
        this.actionLoading = 'resume';
        this.error = null;
        
        const result = await workforceService.endLunch();
        
        this.lunchEndTime = result.lunchEndTime.toDate();
        this.currentStatus = 'Working';
        this.statusMessage = `Returned from lunch at ${this.formatTime(this.lunchEndTime)}`;
        
        this.actionLoading = null;
      } catch (error) {
        console.error('Resume work failed:', error);
        this.error = error.message || 'Failed to resume work. Please try again.';
        this.actionLoading = null;
      }
    },
    
    async checkOut() {
      try {
        this.actionLoading = 'checkOut';
        this.error = null;
        
        // Get current location
        const position = await this.getCurrentPosition();
        
        // Take photo (this would require camera integration)
        // const photo = await this.takePhoto();
        
        // Record check-out
        const result = await workforceService.recordCheckOut(position);
        
        // Update component state
        this.checkOutTime = result.checkOutTime.toDate();
        this.currentStatus = 'Checked Out';
        this.statusMessage = `Checked out at ${this.formatTime(this.checkOutTime)}. Total hours: ${result.totalWorkHours.toFixed(2)}`;
        
        this.actionLoading = null;
      } catch (error) {
        console.error('Check-out failed:', error);
        this.error = error.message || 'Failed to check out. Please try again.';
        this.actionLoading = null;
      }
    },
    
    calculateWorkHours() {
      if (!this.checkInTime || !this.checkOutTime) return 0;
      
      let totalMs = this.checkOutTime - this.checkInTime;
      
      // Subtract lunch time if applicable
      if (this.lunchStartTime && this.lunchEndTime) {
        totalMs -= (this.lunchEndTime - this.lunchStartTime);
      }
      
      // Convert to hours
      return totalMs / (1000 * 60 * 60);
    },
    
    formatTime(date) {
      if (!date) return '';
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    },
    
    getCurrentPosition() {
      return new Promise((resolve, reject) => {
        if (!navigator.geolocation) {
          reject(new Error('Geolocation is not supported by your browser'));
          return;
        }
        
        navigator.geolocation.getCurrentPosition(
          position => {
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            });
          },
          error => {
            reject(error);
          }
        );
      });
    },
    
    async loadTodayAttendance() {
      try {
        // Get all of today's attendance records for the user (not just active ones)
        const allAttendanceToday = await workforceService.getAllTodayAttendance();
        
        // Count completed shifts (with checkout)
        const completedShifts = allAttendanceToday.filter(record => record.checkOutTime).length;
        this.completedShifts = completedShifts;
        
        // Get today's schedule to check the number of allowed shifts
        try {
          const todayStart = moment().startOf('day').toDate();
          const todayEnd = moment().endOf('day').toDate();
          
          const schedules = await workforceService.getUserSchedule(
            new Date().getMonth(), 
            new Date().getFullYear()
          );
          
          // Find today's schedule
          const todaySchedule = schedules.find(schedule => {
            const scheduleDate = schedule.date.toDate();
            return scheduleDate >= todayStart && scheduleDate <= todayEnd;
          });
          
          if (todaySchedule) {
            const timeSlots = todaySchedule.timeSlots || [{
              startTime: todaySchedule.startTime || '09:00',
              endTime: todaySchedule.endTime || '17:00'
            }];
            
            this.totalScheduledShifts = timeSlots.length;
            
            // Disable check-in if all shifts are completed
            this.noMoreCheckInsAllowed = completedShifts >= timeSlots.length;
            
            console.log(`Completed ${completedShifts} of ${timeSlots.length} scheduled shifts`);
          } else {
            // No schedule found for today
            this.noMoreCheckInsAllowed = true;
            this.totalScheduledShifts = 0;
          }
        } catch (error) {
          console.error('Error fetching schedule:', error);
          // If we can't get the schedule, don't block check-in
          this.noMoreCheckInsAllowed = false;
        }
        
        if (allAttendanceToday.length > 0) {
          // Find the most recent attendance record
          const mostRecent = allAttendanceToday.reduce((latest, current) => {
            const latestTime = latest.checkInTime.toDate();
            const currentTime = current.checkInTime.toDate();
            return currentTime > latestTime ? current : latest;
          }, allAttendanceToday[0]);
          
          // Update status based on most recent record
          const attendance = mostRecent;
          
          if (!attendance.checkOutTime) {
            // User is currently checked in
            this.currentStatus = attendance.lunchStartTime && !attendance.lunchEndTime ? 'On Lunch' : 'Working';
            this.checkInTime = attendance.checkInTime.toDate();
            
            if (attendance.lunchStartTime && !attendance.lunchEndTime) {
              this.lunchStartTime = attendance.lunchStartTime.toDate();
              this.statusMessage = `Lunch started at ${this.formatTime(this.lunchStartTime)}`;
            } else {
              this.statusMessage = `Working since ${this.formatTime(this.checkInTime)}`;
            }
          } else {
            // User has checked out of their last shift
            this.currentStatus = 'Not Checked In';
            this.checkInTime = null;
            this.checkOutTime = null;
            this.lunchStartTime = null;
            this.lunchEndTime = null;
            
            // Show message about completed shifts
            if (this.noMoreCheckInsAllowed) {
              this.statusMessage = `You have completed all ${this.completedShifts} shift${this.completedShifts > 1 ? 's' : ''} scheduled for today.`;
            } else {
              this.statusMessage = `You have completed ${this.completedShifts} of ${this.totalScheduledShifts} shift${this.totalScheduledShifts > 1 ? 's' : ''} today. You can check in for your next shift.`;
            }
          }
        } else {
          // No attendance records for today
          this.currentStatus = 'Not Checked In';
          if (this.totalScheduledShifts > 0) {
            this.statusMessage = `You have ${this.totalScheduledShifts} shift${this.totalScheduledShifts > 1 ? 's' : ''} scheduled today.`;
          } else {
            this.statusMessage = 'You have not checked in yet.';
          }
        }
      } catch (error) {
        console.error('Error loading today\'s attendance:', error);
        this.error = 'Failed to load attendance data.';
      }
    },
    
    checkIfUserIsAdmin() {
      // Check if user has admin role
      const userClaims = this.$store.state.Auth.token?.claims;
      if (userClaims && (userClaims.type === 'admin' || userClaims.type === 'superuser')) {
        this.isAdmin = true;
      } else {
        this.isAdmin = false;
      }
    },
    
    handleError(message) {
      this.error = message;
    }
  },
  async mounted() {
    this.loading = true;
    this.checkIfUserIsAdmin();
    await this.loadTodayAttendance();
    this.loading = false;
  }
}
</script>

<style scoped>


.status-card {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.action-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

.action-button {
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.action-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.check-in {
  background-color: #4CAF50;
  color: white;
}

.lunch {
  background-color: #FF9800;
  color: white;
}

.resume {
  background-color: #2196F3;
  color: white;
}

.check-out {
  background-color: #F44336;
  color: white;
}

/* .user-schedule, .work-summary, .admin-panel {
  margin-top: 30px;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
} */
</style> 