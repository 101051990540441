<template>
  <div class="calendar-container">
    <v-toolbar flat class="rounded-lg mb-2">
      <v-btn icon @click="prev">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-toolbar-title class="text-capitalize">
        {{ currentMonthName }} {{ currentYear }}
      </v-toolbar-title>
      <v-btn icon @click="next" class="ml-4">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-sheet height="600">
      <v-calendar
        ref="calendar"
        v-model="focus"
        type="month"
        :events="calendarEvents"
        :event-color="getEventColor"
        @click:date="handleDateClick"
        @click:event="handleEventClick"
      >
        <template v-slot:event="{ event }">
          <div class="d-flex align-center">
            <v-icon small left dark class="mr-1">
              mdi-clock-outline
            </v-icon>
            {{ formatEventTime(event) }}
          </div>
        </template>
      </v-calendar>
    </v-sheet>

    <v-dialog v-model="dialog.show" max-width="400">
      <v-card>
        <v-card-title>
          Schedule Details
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-if="dialog.event">
          <div class="mb-2">
            <strong>Date:</strong> {{ formatDate(dialog.event.start) }}
          </div>
          <div class="mb-2">
            <strong>Time:</strong> {{ formatEventTime(dialog.event) }}
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import moment from 'moment';

const db = getFirestore();

export default {
  name: 'AdminScheduleCalendar',
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      focus: moment().format('YYYY-MM-DD'),
      userSchedule: [],
      loading: false,
      error: null,
      dialog: {
        show: false,
        event: null
      },
      debounceTimer: null
    }
  },
  computed: {
    currentMonthName() {
      return moment(this.focus).locale('es').format('MMMM');
    },
    currentYear() {
      return moment(this.focus).format('YYYY');
    },
    calendarEvents() {
      if (!this.userSchedule.length) return [];
      
      return this.userSchedule.flatMap(schedule => {
        if (!schedule.date) return [];
        
        const date = schedule.date.toDate();
        
        if (schedule.timeSlots && schedule.timeSlots.length > 0) {
          return schedule.timeSlots.map(slot => ({
            name: 'Schedule',
            start: moment(date)
              .hour(parseInt(slot.startTime.split(':')[0]))
              .minute(parseInt(slot.startTime.split(':')[1]))
              .format('YYYY-MM-DD HH:mm'),
            end: moment(date)
              .hour(parseInt(slot.endTime.split(':')[0]))
              .minute(parseInt(slot.endTime.split(':')[1]))
              .format('YYYY-MM-DD HH:mm'),
            color: 'primary',
            timed: true,
            details: {
              ...schedule,
              startTime: slot.startTime,
              endTime: slot.endTime
            }
          }));
        }
        
        return [];
      });
    }
  },
  methods: {
    prev() {
      if (this.loading) return;
      
      const newDate = moment(this.focus).subtract(1, 'month').startOf('month');
      this.focus = newDate.format('YYYY-MM-DD');
      this.debouncedFetch();
    },
    next() {
      if (this.loading) return;
      
      const newDate = moment(this.focus).add(1, 'month').startOf('month');
      this.focus = newDate.format('YYYY-MM-DD');
      this.debouncedFetch();
    },
    debouncedFetch() {
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer);
      }
      
      this.debounceTimer = setTimeout(() => {
        this.fetchUserSchedule();
      }, 300);
    },
    formatDate(dateStr) {
      return moment(dateStr).format('MMMM D, YYYY');
    },
    formatEventTime(event) {
      if (event.details.startTime && event.details.endTime) {
        return `${event.details.startTime} - ${event.details.endTime}`;
      }
      return moment(event.start).format('HH:mm') + ' - ' + moment(event.end).format('HH:mm');
    },
    getEventColor(event) {
      return event.color;
    },
    handleDateClick(date) {
      console.log('Date clicked:', date);
    },
    handleEventClick({ event }) {
      this.dialog.event = event;
      this.dialog.show = true;
    },
    async fetchUserSchedule() {
      if (!this.userId || this.loading) return;
      
      this.loading = true;
      this.error = null;
      
      try {
        const currentDate = moment(this.focus);
        
        // Get the first visible day of the calendar (might be from previous month)
        const firstDay = currentDate.clone().startOf('month');
        const startOfCalendar = firstDay.clone().subtract(firstDay.day(), 'days');
        
        // Get the last visible day of the calendar (might be from next month)
        const lastDay = currentDate.clone().endOf('month');
        const daysToAdd = 6 - lastDay.day();
        const endOfCalendar = lastDay.clone().add(daysToAdd, 'days');
        
        console.log('Fetching schedules from', startOfCalendar.format('YYYY-MM-DD'), 'to', endOfCalendar.format('YYYY-MM-DD'));
        
        const scheduleQuery = query(
          collection(db, 'schedulesConfig'),
          where('userId', '==', this.userId),
          where('date', '>=', startOfCalendar.toDate()),
          where('date', '<=', endOfCalendar.toDate())
        );
        
        const scheduleSnapshot = await getDocs(scheduleQuery);
        this.userSchedule = scheduleSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        console.log(`Found ${this.userSchedule.length} schedules`);
      } catch (error) {
        console.error('Error fetching user schedule:', error);
        this.error = 'Failed to load schedule. Please try again.';
      } finally {
        this.loading = false;
      }
    },
    refresh() {
      this.debouncedFetch();
    }
  },
  watch: {
    userId: {
      immediate: true,
      handler() {
        this.debouncedFetch();
      }
    }
  },
  beforeDestroy() {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }
  }
}
</script>

<style scoped>
.calendar-container {
  width: 100%;
  position: relative;
}

:deep(.v-calendar-weekly__head-weekday) {
  border-right: thin solid rgba(224, 224, 224, 0.1) !important;
  border-bottom: thin solid rgba(224, 224, 224, 0.1) !important;
}

:deep(.v-calendar-weekly__day) {
  border-right: thin solid rgba(224, 224, 224, 0.1) !important;
  border-bottom: thin solid rgba(224, 224, 224, 0.1) !important;
}

:deep(.v-calendar-weekly) {
  border: thin solid rgba(224, 224, 224, 0.1) !important;
}
</style> 