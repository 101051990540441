<template>
  <v-container fluid class="admin-panel-container pa-0">
    <v-card elevation="0" class="pa-0 ma-0">
      <v-divider/>
      <v-tabs v-model="activeTab" :show-arrows="false">
        <v-tab >Usuarios</v-tab>
        <v-tab>Horarios</v-tab>
        <v-tab>Reportes</v-tab>
        <v-tab>Alertas

          <v-chip class="ml-2" v-show="filteredAlerts.length > 0"
            x-small
            color="error"
          >
            {{this.filteredAlerts.length}}
          </v-chip>
        </v-tab>

          <!-- Users Tab -->
          <v-tab-item >
            <v-divider/>
            <v-card flat>
              <v-card-title>
                Gestión de Usuarios
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="userSearch"
                  append-icon="mdi-magnify"
                  label="Buscar usuarios"
                  single-line
                  hide-details
                  filled
                  rounded
                  dense
                  class="rounded-lg"
                ></v-text-field>
              </v-card-title>
              <v-divider/>

              <v-data-table
                :headers="[
                  { text: 'Nombre', value: 'name' },
                  { text: 'Estado', value: 'status' },
                  { text: 'Horas Hoy', value: 'todayHours' },
                  { text: 'Horas Semanales', value: 'weeklyHours' },
                  { text: 'Acciones', value: 'actions', sortable: false }
                ]"
                :items="filteredUsers"
                :search="userSearch"
                :loading="loading.users"
                class="elevation-1"
              >
                <template v-slot:item.status="{ item }">
                  <v-chip
                    :color="getStatusColor(item.status)"
                    text-color="white"
                    small
                  >
                    {{ item.status }}
                  </v-chip>
                </template>
                
                <template v-slot:item.actions="{ item }">
                  <v-btn
                    small
                   
                    @click="viewUserSchedule(item)"
                   
                  >
                    <v-icon small left>mdi-eye</v-icon>
                    Ver
                  </v-btn>
              
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>

          <!-- Schedules Tab -->
          <v-tab-item >
            <v-divider/>

            <v-card flat class="pa-0">
              
              <v-card-text v-if="selectedUser">
                <v-row>
                  <v-col cols="12"  md="8">
                    <v-card outlined>
                      <v-card-title>
                        {{ selectedUser.name }}
                        <v-chip
                          :color="getStatusColor(selectedUser.status)"
                          text-color="white"
                          class="ml-4"
                        >
                          {{ selectedUser.status }}
                        </v-chip>
                      </v-card-title>
                      
                      <v-card-text>
                        <AdminScheduleCalendar ref="adminCalendar" :userId="selectedUser.id" />
                      </v-card-text>
                    </v-card>
                  </v-col>
                  
                  <v-col cols="12" md="4">
                    <v-card outlined>
                      <v-card-title>Editor de Horarios</v-card-title>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12">
                            <v-card-subtitle>Seleccionar Días</v-card-subtitle>
                            <v-chip-group
                              v-model="scheduleTemplate.days"
                              multiple
                              column
                            >
                              <v-chip
                                v-for="(day, index) in weekDays"
                                :key="index"
                                :value="index"
                                filter
                                outlined
                              >
                                {{ day }}
                              </v-chip>
                            </v-chip-group>
                          </v-col>
                          
                          <v-col cols="12">
                            <v-card-subtitle>Horarios</v-card-subtitle>
                            <v-row
                              v-for="(slot, index) in scheduleSlots"
                              :key="index"
                              align="center"
                              class="mb-2"
                            >
                              <v-col cols="12" sm="5">
                                <v-text-field
                                  v-model="slot.startTime"
                                  label="Hora de Inicio"
                                  type="time"
                                  outlined
                                  dense
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="5">
                                <v-text-field
                                  v-model="slot.endTime"
                                  label="Hora de Fin"
                                  type="time"
                                  outlined
                                  dense
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="2">
                                <v-btn
                                  icon
                                  color="error"
                                  @click="removeTimeSlot(index)"
                                  v-if="scheduleSlots.length > 1"
                                >
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                            
                            <v-btn
                              color="primary"
                              @click="addTimeSlot"
                              class="mt-2"
                            >
                              <v-icon left>mdi-plus</v-icon>
                              Agregar Horario
                            </v-btn>
                          </v-col>
                          
                          <v-col cols="12" sm="6">
                            <v-menu
                              v-model="startDateMenu"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="scheduleTemplate.startDate"
                                  label="Fecha de Inicio"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  outlined
                                  dense
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="scheduleTemplate.startDate"
                                @input="startDateMenu = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          
                          <v-col cols="12" sm="6">
                            <v-menu
                              v-model="endDateMenu"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="scheduleTemplate.endDate"
                                  label="Fecha de Fin"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  outlined
                                  dense
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="scheduleTemplate.endDate"
                                @input="endDateMenu = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="error"
                          text
                          @click="cancelScheduleEdit"
                        >
                          Cancelar
                        </v-btn>
                        <v-btn
                          color="success"
                          :loading="loading.schedule"
                          @click="applySchedule"
                        >
                          Aplicar Horario
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
              
              <v-card-text v-else>
                <v-alert
                  type="info"
                  text
                >
                  Seleccione un usuario de la pestaña Usuarios para editar su horario.
                </v-alert>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- Reports Tab -->
          <v-tab-item>
            <v-divider/>

            <v-card flat>
              <v-card-title>Reportes de Personal</v-card-title>
              
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-select
                      v-model="reportFilter.dateRange"
                      :items="[
                        { text: 'Hoy', value: 'today' },
                        { text: 'Ayer', value: 'yesterday' },
                        { text: 'Esta Semana', value: 'thisWeek' },
                        { text: 'Semana Pasada', value: 'lastWeek' },
                        { text: 'Este Mes', value: 'thisMonth' },
                        { text: 'Rango Personalizado', value: 'custom' }
                      ]"
                      label="Rango de Fechas"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  
                  <v-col cols="12" sm="4" v-if="reportFilter.dateRange === 'custom'">
                    <v-menu
                      v-model="startReportDateMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="reportFilter.startDate"
                          label="Desde"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="reportFilter.startDate"
                        @input="startReportDateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  
                  <v-col cols="12" sm="4" v-if="reportFilter.dateRange === 'custom'">
                    <v-menu
                      v-model="endReportDateMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="reportFilter.endDate"
                          label="Hasta"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="reportFilter.endDate"
                        @input="endReportDateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  
                  <v-col cols="12" sm="4">
                    <v-select
                      v-model="reportFilter.userFilter"
                      :items="[
                        { text: 'Todos los Usuarios', value: 'all' },
                        { text: 'Usuarios Activos', value: 'active' },
                        { text: 'Usuarios Seleccionados', value: 'selected' }
                      ]"
                      label="Usuarios"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  
                  <v-col cols="12" sm="4">
                    <v-switch
                      v-model="reportFilter.showAdherence"
                      label="Mostrar Cumplimiento"
                      color="primary"
                    ></v-switch>
                  </v-col>
                  
                  <v-col cols="12">
                    <v-btn
                      color="primary"
                      :loading="loading.reports"
                      @click="generateReport"
                    >
                      <v-icon left>mdi-file-chart</v-icon>
                      Generar Reporte
                    </v-btn>
                  </v-col>
                </v-row>
                
                <v-data-table
                  v-if="reportData.length > 0"
                  :headers="getReportHeaders()"
                  :items="reportData"
                  class="elevation-1 mt-4"
                  :item-class="getReportRowClass"
                >
                  <template v-slot:item.adherence="{ item }">
                    <div :class="item.adherenceClass">
                      {{ item.adherence }}
                      <div v-if="item.isSummary" class="adherence-details">
                        <div v-if="item.missedShifts > 0">Shifts perdidos: {{ item.missedShifts }}</div>
                        <div v-if="item.lateArrivals > 0">Llegadas tardías: {{ item.lateArrivals }}</div>
                        <div v-if="item.earlyDepartures > 0">Salidas tempranas: {{ item.earlyDepartures }}</div>
                      </div>
                    </div>
                  </template>
                </v-data-table>
                
                <v-card-actions v-if="reportData.length > 0">
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    @click="downloadReport('csv')"
                    class="mr-2"
                  >
                    <v-icon left>mdi-download</v-icon>
                    Descargar CSV
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="downloadReport('pdf')"
                    class="mr-2"
                  >
                    <v-icon left>mdi-file-pdf</v-icon>
                    Descargar PDF
                  </v-btn>
                  <v-btn
                    color="grey darken-1"
                    @click="printReport"
                    text
                  >
                    <v-icon left>mdi-printer</v-icon>
                    Imprimir
                  </v-btn>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- Alerts Tab -->
          <v-tab-item >
            <v-divider/>

            <v-card flat>
              <v-card-title>
                Alertas de Personal
                <v-spacer></v-spacer>
                <v-select
                  v-model="alertFilter.type"
                  :items="[
                    { text: 'Todos los Tipos', value: 'all' },
                    { text: 'Entradas Tardías', value: 'late' },
                    { text: 'Salidas Tempranas', value: 'early' },
                    { text: 'Salidas Faltantes', value: 'missing' },
                    { text: 'Descansos Largos', value: 'longBreak' }
                  ]"
                  label="Tipo"
                  outlined
                  dense
                  class="mx-2"
                  style="max-width: 200px"
                  hide-details
                ></v-select>
                <v-select
                  v-model="alertFilter.period"
                  :items="[
                    { text: 'Hoy', value: 'today' },
                    { text: 'Esta Semana', value: 'week' },
                    { text: 'Este Mes', value: 'month' }
                  ]"
                  label="Período"
                  outlined
                  dense
                  style="max-width: 200px"
                  hide-details
                ></v-select>
              </v-card-title>
              <v-divider/>

              <v-card-text>
                <v-progress-linear v-if="loading.alerts" indeterminate color="primary"></v-progress-linear>
                
                <div v-else>
                  <v-alert
                    v-if="filteredAlerts.length === 0"
                    type="info"
                    text
                  >
                    No se encontraron alertas para los filtros seleccionados.
                  </v-alert>
                  
                  <v-alert text
                    v-for="alert in filteredAlerts"
                    :key="alert.id"
                    :class="{ 
                      'mb-4': true,
                      'resolved': resolvedAlerts.has(alert.id)
                    }"
                     :color="getAlertColor(alert.severity)"
                  >
                    <v-card-title class="py-2">
                    
                      <div class="d-flex align-center">
                        <span>{{ getTranslatedAlertType(alert.type) }}</span>
                        <v-chip
                        
                          small
                          class="ml-2"
                          label
                          outlined
                        >
                          {{ getAlertSeverityText(alert.severity) }}
                        </v-chip>
                      </div>
                      <v-spacer></v-spacer>
                      <span class="caption grey--text">{{ alert.time }}</span>
                    </v-card-title>
                    
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <div class="subtitle-1 font-weight-bold mb-2">{{ alert.user }}</div>
                          <div class="body-2">{{ alert.message }}</div>
                          
                          <div class="mt-3" v-if="alert.minutes">
                            <v-chip
                              :color="getMetricColor(alert.minutes)"
                              outlined
                              small
                            >
                              {{ 
                                alert.type === 'Late Check-in' ? `${alert.minutes} minutos tarde` :
                                alert.type === 'Early Check-out' ? `${alert.minutes} minutos temprano` :
                                alert.type === 'Long Break' ? `${alert.minutes} minutos de descanso` :
                                `${alert.minutes} minutos`
                              }}
                            </v-chip>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    
                    <v-card-actions v-if="alert.type === 'Missing Check-out'">
                      <v-spacer></v-spacer>
                      <v-btn
                        color="error"
                        @click="forceCheckout(alert)"
                        text
                      >
                        <v-icon left>mdi-logout</v-icon>
                        Forzar Salida
                      </v-btn>
                    </v-card-actions>
                  </v-alert>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
      </v-tabs>

  
    </v-card>
  </v-container>
</template>

<script>
import { getFirestore, collection, query, where, getDocs, doc, updateDoc, deleteDoc, orderBy, Timestamp, addDoc, serverTimestamp, getDoc, writeBatch } from 'firebase/firestore';
import moment from 'moment';
import AdminScheduleCalendar from './AdminScheduleCalendar.vue';

const db = getFirestore();

export default {
  name: 'WorkforceAdminPanel',
  components: {
    AdminScheduleCalendar
  },
  data() {
    return {
      activeTab: 0,
      userSearch: '',
      users: [],
      loading: {
        users: false,
        schedules: false,
        reports: false,
        alerts: false,
        schedule: false,
        userSchedule: false
      },
      selectedUser: null,
      weekDays: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
      scheduleTemplate: {
        days: [],
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().add(30, 'days').format('YYYY-MM-DD')
      },
      scheduleSlots: [{
        startTime: '09:00',
        endTime: '17:00'
      }],
      reportFilter: {
        dateRange: 'thisWeek',
        startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        type: 'adherence',
        users: [],
        showAdherence: true
      },
      reportData: [],
      alertFilter: {
        type: 'all',
        period: 'today'
      },
      alerts: [],
      selectedUserSchedule: [],
      resolvedAlerts: new Set(),
      startReportDateMenu: false,
      endReportDateMenu: false,
      startDateMenu: false,
      endDateMenu: false,
      startReportDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      endReportDate: moment().format('YYYY-MM-DD'),
    }
  },
  computed: {
    filteredUsers() {
      if (!this.userSearch) return this.users;
      
      const search = this.userSearch.toLowerCase();
      return this.users.filter(user => 
        user.name.toLowerCase().includes(search) || 
        user.status.toLowerCase().includes(search)
      );
    },
    filteredAlerts() {
      let filtered = this.alerts.filter(alert => !this.resolvedAlerts.has(alert.id));
      
      if (this.alertFilter.type !== 'all') {
        const typeMap = {
          'late': 'Late Check-in',
          'early': 'Early Check-out',
          'missing': 'Missing Check-out',
          'longBreak': 'Long Break',
          'consistent': 'Consistent Late Arrivals'
        };
        
        filtered = filtered.filter(alert => 
          alert.type === typeMap[this.alertFilter.type]
        );
      }
      
      if (this.alertFilter.period !== 'today') {
        const now = moment();
        const periodStart = this.alertFilter.period === 'week' 
          ? moment().subtract(7, 'days')
          : moment().subtract(30, 'days');
        
        filtered = filtered.filter(alert => {
          const alertTime = moment(alert.timestamp);
          return alertTime.isAfter(periodStart) && alertTime.isBefore(now);
        });
      }
      
      return filtered;
    },
    userMap() {
      const map = {};
      this.users.forEach(user => {
        map[user.id] = user.name;
      });
      return map;
    }
  },
  methods: {
    getTranslatedAlertType(type) {
      const translations = {
        'Late Arrival': 'Llegada Tardía',
        'Missed Shift': 'Turno Faltante',
        'Early Departure': 'Salida Temprana',
        'Missing Check-out': 'Salida Faltante',
        'Long Break': 'Descanso Largo',
        'Consistent Late Arrivals': 'Llegadas tardías consistentes'
      };
      return translations[type] || type;
    },
    
    async fetchUsers() {
      this.loading.users = true;
      try {
        const usersQuery = query(
          collection(db, 'users'),
          where('type', '==', 'entrenador'),
          where('disabled', '==', false)
        );
        
        const usersSnapshot = await getDocs(usersQuery);
        
        const users = [];
        
        // Fetch all attendance records for today to get current status
        const todayStart = moment().startOf('day').toDate();
        const todayEnd = moment().endOf('day').toDate();
        
        const attendanceQuery = query(
          collection(db, 'attendance'),
          where('date', '>=', todayStart),
          where('date', '<=', todayEnd),
          orderBy('date', 'desc')
        );
        
        const attendanceSnapshot = await getDocs(attendanceQuery);
        
        // Group attendance by userId
        const attendanceByUser = {};
        attendanceSnapshot.docs.forEach(doc => {
          const data = doc.data();
          if (!attendanceByUser[data.userId]) {
            attendanceByUser[data.userId] = [];
          }
          attendanceByUser[data.userId].push(data);
        });
        
        // Process each user
        for (const userDoc of usersSnapshot.docs) {
          const userData = userDoc.data();
          
          // Use displayName from userData as the primary name source
          const userName = userData.displayName || userData.name || 'Unknown User';
          
          const userId = userDoc.id;
          const userAttendance = attendanceByUser[userId] || [];
          
          // Calculate total hours worked today
          let todayHours = 0;
          let weeklyHours = 0;
          let status = 'No ha Registrado Entrada';
          
          // Sort attendance records by date (newest first)
          userAttendance.sort((a, b) => {
            return b.date.toDate() - a.date.toDate();
          });
          
          if (userAttendance.length > 0) {
            const latestRecord = userAttendance[0];
            
            // Calculate today's hours from all completed shifts
            userAttendance.forEach(record => {
              if (record.totalWorkHours) {
                todayHours += record.totalWorkHours;
              }
            });
            
            // Determine current status from latest record
            if (!latestRecord.checkOutTime) {
              status = latestRecord.lunchStartTime && !latestRecord.lunchEndTime ? 'En Descanso' : 'Trabajando';
            } else {
              // User has checked out but may have done multiple shifts
              const completedShifts = userAttendance.filter(a => a.checkOutTime).length;
              status = completedShifts > 1 ? `Completó ${completedShifts} Turnos` : 'Registró Salida';
            }
          }
          
          // Calculate weekly hours
          const weekStart = moment().startOf('week').toDate();
          const weekEnd = moment().endOf('week').toDate();
          
          const weeklyAttendanceQuery = query(
            collection(db, 'attendance'),
            where('userId', '==', userId),
            where('date', '>=', weekStart),
            where('date', '<=', weekEnd)
          );
          
          const weeklyAttendanceSnapshot = await getDocs(weeklyAttendanceQuery);
          
          weeklyAttendanceSnapshot.docs.forEach(doc => {
            const data = doc.data();
            if (data.totalWorkHours) {
              weeklyHours += data.totalWorkHours;
            }
          });
          
          users.push({
            id: userId,
            name: userName,
            status,
            todayHours: todayHours.toFixed(2),
            weeklyHours: weeklyHours.toFixed(2)
          });
        }
        
        this.users = users;
      } catch (error) {
        console.error('Error fetching users:', error);
        this.$emit('error', 'Failed to load users');
      } finally {
        this.loading.users = false;
      }
    },
    
    viewUserSchedule(user) {
      this.selectedUser = user;
      this.activeTab = 1;
      this.fetchUserSchedule(user.id);
    },
    
    editUserSchedule(user) {
      this.selectedUser = user;
      this.activeTab = 1;
      
      this.scheduleSlots = [{
        startTime: '09:00',
        endTime: '17:00'
      }];
      
      this.scheduleTemplate = {
        days: [],
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().add(30, 'days').format('YYYY-MM-DD')
      };
      
      this.fetchUserSchedule(user.id);
    },
    
    async fetchUserSchedule(userId) {
      this.loading.userSchedule = true;
      try {
        const currentMonth = new Date().getMonth();
        const currentYear = new Date().getFullYear();
        
        const scheduleQuery = query(
          collection(db, 'schedulesConfig'),
          where('userId', '==', userId),
          where('month', '==', currentMonth),
          where('year', '==', currentYear)
        );
        
        const scheduleSnapshot = await getDocs(scheduleQuery);
        this.selectedUserSchedule = scheduleSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
      } catch (error) {
        console.error('Error fetching user schedule:', error);
        this.$emit('error', 'Failed to load user schedule');
      } finally {
        this.loading.userSchedule = false;
      }
    },
    
    addTimeSlot() {
      this.scheduleSlots.push({
        startTime: '09:00',
        endTime: '17:00'
      });
    },
    
    removeTimeSlot(index) {
      if (this.scheduleSlots.length > 1) {
        this.scheduleSlots.splice(index, 1);
      }
    },
    
    async applySchedule() {
      if (!this.selectedUser || !this.scheduleTemplate.days.length) return;
      
      this.loading.schedule = true;
      const db = getFirestore();
      const batch = writeBatch(db);
      
      try {
        const startDate = moment(this.scheduleTemplate.startDate);
        const endDate = moment(this.scheduleTemplate.endDate);
        
        // Delete existing schedules in the date range
        const existingSchedulesQuery = query(
          collection(db, 'schedulesConfig'),
          where('userId', '==', this.selectedUser.id),
          where('date', '>=', startDate.toDate()),
          where('date', '<=', endDate.toDate())
        );
        
        const existingSchedules = await getDocs(existingSchedulesQuery);
        existingSchedules.docs.forEach(doc => {
          batch.delete(doc.ref);
        });
        
        // Create new schedules
        const currentDate = moment(startDate);
        while (currentDate.isSameOrBefore(endDate)) {
          const dayOfWeek = currentDate.day();
          
          if (this.scheduleTemplate.days.includes(dayOfWeek)) {
            const scheduleRef = doc(collection(db, 'schedulesConfig'));
            const scheduleDate = currentDate.toDate();
            
            batch.set(scheduleRef, {
              userId: this.selectedUser.id,
              date: scheduleDate,
              timeSlots: this.scheduleSlots.map(slot => ({
                startTime: slot.startTime,
                endTime: slot.endTime
              })),
              createdAt: serverTimestamp()
            });
          }
          currentDate.add(1, 'days');
        }
        
        await batch.commit();
        
        // Refresh the calendar
        if (this.$refs.adminCalendar) {
          this.$refs.adminCalendar.refresh();
        }
        
        this.$notify({
          type: 'success',
          title: 'Horario Actualizado',
          text: 'El horario ha sido actualizado exitosamente'
        });
        
      } catch (error) {
        console.error('Error applying schedule:', error);
        this.$notify({
          type: 'error',
          title: 'Error',
          text: 'Hubo un error al actualizar el horario'
        });
      } finally {
        this.loading.schedule = false;
      }
    },
    
    cancelScheduleEdit() {
      this.selectedUser = null;
    },
    
    async generateReport() {
      this.loading.reports = true;
      try {
        let reportResults = [];
        
        // Determine the date range for the report
        const reportStart = moment(this.reportFilter.startDate).startOf('day').toDate();
        const reportEnd = moment(this.reportFilter.endDate).endOf('day').toDate();
        
        // Step 1: First fetch all schedules in the date range
        const schedulesQuery = query(
          collection(db, 'schedulesConfig'),
          where('date', '>=', reportStart),
          where('date', '<=', reportEnd)
        );
        
        const schedulesSnapshot = await getDocs(schedulesQuery);
        const schedules = schedulesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        // Organize schedules by userId and date
        const schedulesByUserAndDate = {};
        const userAttendanceStats = {}; // Track attendance stats per user
        
        schedules.forEach(schedule => {
          const userId = schedule.userId;
          // Use userMap as the primary source of truth for user names
          const userName = this.userMap[userId] || schedule.userName || 'Unknown User';
          const scheduleDate = schedule.date.toDate();
          const dateKey = moment(scheduleDate).format('YYYY-MM-DD');
          
          // Initialize user stats if not exists
          if (!userAttendanceStats[userId]) {
            userAttendanceStats[userId] = {
              userId,
              userName, // Now using the better name source
              scheduledDays: new Set(),
              attendedDays: new Set(),
              totalScheduledShifts: 0,
              totalAttendedShifts: 0,
              missedShifts: 0,
              lateArrivals: 0,
              earlyDepartures: 0
            };
          }
          
          // Add this scheduled day
          userAttendanceStats[userId].scheduledDays.add(dateKey);
          
          // Count scheduled shifts
          const timeSlots = schedule.timeSlots || [{
            startTime: schedule.startTime || '09:00',
            endTime: schedule.endTime || '17:00'
          }];
          
          userAttendanceStats[userId].totalScheduledShifts += timeSlots.length;
          
          // Store schedule by user and date
          if (!schedulesByUserAndDate[userId]) {
            schedulesByUserAndDate[userId] = {};
          }
          
          schedulesByUserAndDate[userId][dateKey] = schedule;
        });
        
        // Step 2: Fetch attendance records for the same period
        const attendanceQuery = query(
          collection(db, 'attendance'),
          where('date', '>=', reportStart),
          where('date', '<=', reportEnd),
          orderBy('date', 'desc')
        );
        
        const attendanceSnapshot = await getDocs(attendanceQuery);
        
        // Group attendance records by user and date
        const attendanceByUserAndDate = {};
        
        attendanceSnapshot.docs.forEach(doc => {
          const data = doc.data();
          if (!data.date) return;
          
          const userId = data.userId;
          const attendanceDate = data.date.toDate();
          const dateKey = moment(attendanceDate).format('YYYY-MM-DD');
          
          if (!attendanceByUserAndDate[userId]) {
            attendanceByUserAndDate[userId] = {};
          }
          
          if (!attendanceByUserAndDate[userId][dateKey]) {
            attendanceByUserAndDate[userId][dateKey] = [];
          }
          
          attendanceByUserAndDate[userId][dateKey].push({
            id: doc.id,
            ...data
          });
          
          // Update user stats
          if (userAttendanceStats[userId]) {
            userAttendanceStats[userId].attendedDays.add(dateKey);
            userAttendanceStats[userId].totalAttendedShifts += 1;
          }
        });
        
        // Step 3: Process attendance records and add to report
        for (const userId in attendanceByUserAndDate) {
          for (const dateKey in attendanceByUserAndDate[userId]) {
            const records = attendanceByUserAndDate[userId][dateKey];
            const schedule = schedulesByUserAndDate[userId]?.[dateKey];
            
            // Process each attendance record
            for (const data of records) {
              let attendanceDate = data.date.toDate();
              
              // Get check-in and check-out times
              let checkInTime = data.checkInTime ? data.checkInTime.toDate() : null;
              let checkOutTime = data.checkOutTime ? data.checkOutTime.toDate() : null;
              
              // Get time slots from schedule
              const timeSlots = schedule ? (schedule.timeSlots || [{
                startTime: schedule.startTime || '09:00',
                endTime: schedule.endTime || '17:00'
              }]) : [];
              
              // Find the appropriate time slot for this attendance record
              let matchingSlot = null;
              
              if (checkInTime && timeSlots.length > 0) {
                // If we have a slotIndex saved in the attendance record, use that
                if (data.slotIndex !== undefined && timeSlots[data.slotIndex]) {
                  matchingSlot = timeSlots[data.slotIndex];
                } else {
                  // Otherwise find the closest time slot to the check-in time
                  let minTimeDiff = Infinity;
                  
                  timeSlots.forEach(slot => {
                    const startTimeParts = slot.startTime.split(':');
                    const scheduledStart = new Date(attendanceDate);
                    scheduledStart.setHours(parseInt(startTimeParts[0]), parseInt(startTimeParts[1]), 0, 0);
                    
                    const timeDiff = Math.abs(checkInTime - scheduledStart);
                    if (timeDiff < minTimeDiff) {
                      minTimeDiff = timeDiff;
                      matchingSlot = slot;
                    }
                  });
                }
              }
              
              // For report display, use the first and last slots to show the full range
              const firstSlot = timeSlots[0] || { startTime: '09:00', endTime: '17:00' };
              const lastSlot = timeSlots[timeSlots.length - 1] || firstSlot;
              
              // Calculate adherence
              let adherence = 'N/A';
              let adherenceClass = '';
              
              if (schedule && checkInTime) {
                if (!checkOutTime) {
                  adherence = 'No Checkout';
                  adherenceClass = 'warning';
                  
                  // Track stats
                  if (userAttendanceStats[userId]) {
                    userAttendanceStats[userId].missedShifts += 0.5; // Half missing since they at least checked in
                  }
                } else {
                  // Calculate adherence based on matching slot
                  if (matchingSlot) {
                    const startTimeParts = matchingSlot.startTime.split(':');
                    const endTimeParts = matchingSlot.endTime.split(':');
                    
                    const scheduledStart = new Date(attendanceDate);
                    scheduledStart.setHours(parseInt(startTimeParts[0]), parseInt(startTimeParts[1]), 0, 0);
                    
                    const scheduledEnd = new Date(attendanceDate);
                    scheduledEnd.setHours(parseInt(endTimeParts[0]), parseInt(endTimeParts[1]), 0, 0);
                    
                    if (checkInTime > new Date(scheduledStart.getTime() + 15 * 60 * 1000)) {
                      adherence = 'Late';
                      adherenceClass = 'warning';
                      
                      // Track late arrivals
                      if (userAttendanceStats[userId]) {
                        userAttendanceStats[userId].lateArrivals += 1;
                      }
                    } else if (checkOutTime < new Date(scheduledEnd.getTime() - 15 * 60 * 1000)) {
                      adherence = 'Left Early';
                      adherenceClass = 'warning';
                      
                      // Track early departures
                      if (userAttendanceStats[userId]) {
                        userAttendanceStats[userId].earlyDepartures += 1;
                      }
                    } else {
                      adherence = 'Good';
                      adherenceClass = 'good';
                    }
                  }
                }
              }
              
              // Create the report record
              reportResults.push({
                id: data.id,
                userName: data.userName || this.userMap[data.userId] || 'Unknown User',
                userId: data.userId,
                date: attendanceDate.toLocaleDateString(),
                checkIn: checkInTime ? checkInTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : 'N/A',
                checkOut: checkOutTime ? checkOutTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : 'N/A',
                lunchDuration: data.lunchStartTime && data.lunchEndTime ? 
                  `${Math.round((data.lunchEndTime.toDate() - data.lunchStartTime.toDate()) / (1000 * 60))} min` : 'N/A',
                totalHours: data.totalWorkHours ? data.totalWorkHours.toFixed(2) : 'N/A',
                scheduledShift: matchingSlot ? `${matchingSlot.startTime} - ${matchingSlot.endTime}` : 'N/A',
                scheduledRange: timeSlots.length > 1 ? 
                  `${firstSlot.startTime} - ${lastSlot.endTime} (${timeSlots.length} shifts)` : 
                  `${firstSlot.startTime} - ${lastSlot.endTime}`,
                adherence,
                adherenceClass,
                slotIndex: data.slotIndex !== undefined ? data.slotIndex + 1 : 'N/A',
                totalSlots: timeSlots.length,
                recordType: 'attendance'
              });
            }
          }
        }
        
        // Step 4: Find schedules without corresponding attendance records (no-shows)
        for (const userId in schedulesByUserAndDate) {
          for (const dateKey in schedulesByUserAndDate[userId]) {
            const schedule = schedulesByUserAndDate[userId][dateKey];
            const hasAttendance = attendanceByUserAndDate[userId]?.[dateKey]?.length > 0;
            
            // Only process past dates (can't be a no-show for future shifts)
            const scheduleDate = schedule.date.toDate();
            const isDateInPast = scheduleDate < new Date();
            
            if (isDateInPast && !hasAttendance) {
              // This is a no-show - add to the report
              const timeSlots = schedule.timeSlots || [{
                startTime: schedule.startTime || '09:00',
                endTime: schedule.endTime || '17:00'
              }];
              
              // Track missed shifts in stats
              if (userAttendanceStats[userId]) {
                userAttendanceStats[userId].missedShifts += timeSlots.length;
              }
              
              // Add an entry for each missed time slot
              timeSlots.forEach((slot, index) => {
                reportResults.push({
                  id: `no-show-${userId}-${dateKey}-${index}`,
                  userName: schedule.userName || this.userMap[userId] || 'Unknown User',
                  userId: userId,
                  date: scheduleDate.toLocaleDateString(),
                  checkIn: 'No Show',
                  checkOut: 'No Show',
                  lunchDuration: 'N/A',
                  totalHours: '0.00',
                  scheduledShift: `${slot.startTime} - ${slot.endTime}`,
                  scheduledRange: timeSlots.length > 1 ? 
                    `${timeSlots[0].startTime} - ${timeSlots[timeSlots.length - 1].endTime} (${timeSlots.length} shifts)` : 
                    `${timeSlots[0].startTime} - ${timeSlots[0].endTime}`,
                  adherence: 'Absent',
                  adherenceClass: 'error',
                  slotIndex: index + 1,
                  totalSlots: timeSlots.length,
                  recordType: 'no-show'
                });
              });
            }
          }
        }
        
        // Step 5: Add user summary statistics to the report
        for (const userId in userAttendanceStats) {
          const stats = userAttendanceStats[userId];
          // Double-check the userName here too
          const userName = this.userMap[userId] || stats.userName || 'Unknown User';
          
          const attendanceRate = stats.totalScheduledShifts > 0 ? 
            ((stats.totalAttendedShifts / stats.totalScheduledShifts) * 100).toFixed(0) : '0';
          
          // Calculate average days per week
          const totalDays = moment(reportEnd).diff(moment(reportStart), 'days') + 1;
          const totalWeeks = totalDays / 7;
          const avgDaysPerWeek = stats.scheduledDays.size > 0 ? 
            (stats.scheduledDays.size / totalWeeks).toFixed(1) : '0';
          
          reportResults.push({
            id: `summary-${userId}`,
            userName: `${userName} (Summary)`, // Now using the better name source
            userId: userId,
            date: `${moment(reportStart).format('MMM D')} - ${moment(reportEnd).format('MMM D, YYYY')}`,
            checkIn: '—',
            checkOut: '—',
            lunchDuration: '—',
            totalHours: '—',
            scheduledShift: `${stats.totalAttendedShifts} of ${stats.totalScheduledShifts} shifts`,
            scheduledRange: `${stats.attendedDays.size} of ${stats.scheduledDays.size} days (${avgDaysPerWeek} days/wk)`,
            adherence: `${attendanceRate}% Attendance`,
            adherenceClass: parseInt(attendanceRate) >= 90 ? 'good' : parseInt(attendanceRate) >= 75 ? 'warning' : 'error',
            isSummary: true,
            recordType: 'summary',
            missedShifts: stats.missedShifts,
            lateArrivals: stats.lateArrivals,
            earlyDepartures: stats.earlyDepartures
          });
        }
        
        // Apply user filter if specified
        if (this.reportFilter.users.length > 0) {
          reportResults = reportResults.filter(record => 
            this.reportFilter.users.includes(record.userId)
          );
        }
        
        // Sort results
        reportResults.sort((a, b) => {
          // Summaries at the very top
          if (a.recordType === 'summary' && b.recordType !== 'summary') return -1;
          if (a.recordType !== 'summary' && b.recordType === 'summary') return 1;
          
          // Then by date (newest first for regular entries)
          if (a.recordType !== 'summary' && b.recordType !== 'summary') {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            if (dateB - dateA !== 0) return dateB - dateA;
          }
          
          // Then by user name
          const userCompare = a.userName.localeCompare(b.userName);
          if (userCompare !== 0) return userCompare;
          
          // Then by record type (no-shows after attendance)
          if (a.recordType === 'no-show' && b.recordType === 'attendance') return 1;
          if (a.recordType === 'attendance' && b.recordType === 'no-show') return -1;
          
          return 0;
        });
        
        this.reportData = reportResults;
      } catch (error) {
        console.error('Error generating report:', error);
        this.$emit('error', 'Failed to generate report');
      } finally {
        this.loading.reports = false;
      }
    },
    
    downloadReport(format) {
      if (this.reportData.length === 0) {
        this.$emit('error', 'No data to download');
        return;
      }
      
      if (format === 'csv') {
        const headers = Object.keys(this.reportData[0]);
        const csvContent = [
          headers.join(','),
          ...this.reportData.map(row => 
            headers.map(key => {
              let value = row[key];
              if (typeof value === 'string' && (value.includes(',') || value.includes('"'))) {
                value = `"${value.replace(/"/g, '""')}"`;
              }
              return value;
            }).join(',')
          )
        ].join('\n');
        
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        
        const url = URL.createObjectURL(blob);
        
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `workforce_report_${moment().format('YYYY-MM-DD')}.csv`);
        document.body.appendChild(link);
        link.click();
        
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } else if (format === 'pdf') {
        alert('PDF download not implemented yet');
      }
    },
    
    printReport() {
      window.print();
    },
    
    async fetchAlerts() {
      // Don't fetch alerts if users haven't been loaded yet
      if (this.users.length === 0) {
        await this.fetchUsers();
      }

      this.loading.alerts = true;
      try {
        const alerts = [];
        let periodStart;
        
        // Determine the date range based on the period filter
        switch (this.alertFilter.period) {
          case 'today':
            periodStart = moment().startOf('day').toDate();
            break;
          case 'week':
            periodStart = moment().subtract(7, 'days').startOf('day').toDate();
            break;
          case 'month':
            periodStart = moment().subtract(30, 'days').startOf('day').toDate();
            break;
          default:
            periodStart = moment().startOf('day').toDate();
        }
        
        console.log('Fetching alerts from', periodStart, 'to', moment().endOf('day').toDate());
        
        // Get all schedules for the selected period
        const schedulesQuery = query(
          collection(db, 'schedulesConfig'),
          where('date', '>=', periodStart),
          where('date', '<=', moment().endOf('day').toDate())
        );
        
        const schedulesSnapshot = await getDocs(schedulesQuery);
        const schedules = schedulesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        // Organize schedules by date and user
        const schedulesByDateAndUser = {};
        
        schedules.forEach(schedule => {
          const scheduleDate = schedule.date.toDate();
          const dateKey = moment(scheduleDate).format('YYYY-MM-DD');
          const userId = schedule.userId;
          
          if (!schedulesByDateAndUser[dateKey]) {
            schedulesByDateAndUser[dateKey] = {};
          }
          
          schedulesByDateAndUser[dateKey][userId] = schedule;
        });
        
        // Get attendance records for the selected period
        const attendanceQuery = query(
          collection(db, 'attendance'),
          where('date', '>=', periodStart),
          where('date', '<=', moment().endOf('day').toDate())
        );
        
        const attendanceSnapshot = await getDocs(attendanceQuery);
        
        // Group attendance by date and user
        const attendanceByDateAndUser = {};
        
        attendanceSnapshot.docs.forEach(doc => {
          const data = doc.data();
          if (!data.date) return;
          
          const attendanceDate = data.date.toDate();
          const dateKey = moment(attendanceDate).format('YYYY-MM-DD');
          const userId = data.userId;
          
          if (!attendanceByDateAndUser[dateKey]) {
            attendanceByDateAndUser[dateKey] = {};
          }
          
          if (!attendanceByDateAndUser[dateKey][userId]) {
            attendanceByDateAndUser[dateKey][userId] = [];
          }
          
          attendanceByDateAndUser[dateKey][userId].push({
            id: doc.id,
            ...data
          });
        });
        
        // Process each day
        const now = new Date();
        const today = moment(now).format('YYYY-MM-DD');
        
        // Track users with multiple late arrivals
        const lateArrivalsCount = {};
        
        // First check each scheduled shift for alerts
        for (const dateKey in schedulesByDateAndUser) {
          for (const userId in schedulesByDateAndUser[dateKey]) {
            const schedule = schedulesByDateAndUser[dateKey][userId];
            const timeSlots = schedule.timeSlots || [{
              startTime: schedule.startTime || '09:00',
              endTime: schedule.endTime || '17:00'
            }];
            
            const attendanceRecords = attendanceByDateAndUser[dateKey]?.[userId] || [];
            const userName = this.userMap[userId] || schedule.userName || 'Unknown User';
            
            // If this is a past or current day
            const scheduleDate = schedule.date.toDate();
            const isPastOrCurrentDay = scheduleDate <= now;
            
            if (isPastOrCurrentDay) {
              // First identify missing attendance
              if (attendanceRecords.length === 0) {
                // No attendance records for a scheduled day that has passed
                if (dateKey !== today) { // Only report as missed for past days
                  timeSlots.forEach((slot, index) => {
                    alerts.push({
                      id: `missed-${userId}-${dateKey}-${index}`,
                      type: 'Missed Shift',
                      severity: 'error',
                      user: userName,
                      userId,
                      time: `${moment(scheduleDate).format('MMM D')} - ${slot.startTime}`,
                      timestamp: scheduleDate,
                      message: `${userName} was scheduled to work on ${moment(scheduleDate).format('MMM D')} at ${slot.startTime} but did not check in.`,
                      actionRequired: true
                    });
                  });
                } else if (dateKey === today) {
                  // For today, only report as missed if the start time has passed
                  timeSlots.forEach((slot, index) => {
                    const startTimeParts = slot.startTime.split(':');
                    const scheduledStart = new Date(scheduleDate);
                    scheduledStart.setHours(parseInt(startTimeParts[0]), parseInt(startTimeParts[1]), 0, 0);
                    
                    if (now > scheduledStart && now.getTime() - scheduledStart.getTime() > 60 * 60 * 1000) { // 1 hour grace period
                      alerts.push({
                        id: `missed-today-${userId}-${index}`,
                        type: 'Late Arrival',
                        severity: 'error',
                        user: userName,
                        userId,
                        time: `Today - ${slot.startTime}`,
                        timestamp: now,
                        message: `${userName} was scheduled to start at ${slot.startTime} today but has not checked in and is over 1 hour late.`,
                        actionRequired: true
                      });
                    }
                  });
                }
              } else {
                // Check for partial attendance (missing some shifts)
                if (attendanceRecords.length < timeSlots.length && dateKey !== today) {
                  alerts.push({
                    id: `partial-${userId}-${dateKey}`,
                    type: 'Incomplete Shifts',
                    severity: 'warning',
                    user: userName,
                    userId,
                    time: moment(scheduleDate).format('MMM D'),
                    timestamp: scheduleDate,
                    message: `${userName} completed only ${attendanceRecords.length} of ${timeSlots.length} scheduled shifts on ${moment(scheduleDate).format('MMM D')}.`,
                    actionRequired: false
                  });
                }
              }
            }
            
            // Now check each attendance record for specific issues
            attendanceRecords.forEach((record, recordIndex) => {
              // 1. Check for late check-ins
              if (record.checkInTime && record.slotIndex !== undefined && timeSlots[record.slotIndex]) {
                const slot = timeSlots[record.slotIndex];
                const startTimeParts = slot.startTime.split(':');
                const scheduledStart = new Date(scheduleDate);
                scheduledStart.setHours(parseInt(startTimeParts[0]), parseInt(startTimeParts[1]), 0, 0);
                
                const checkInTime = record.checkInTime.toDate();
                const minutesLate = Math.round((checkInTime - scheduledStart) / (1000 * 60));
                
                if (minutesLate > 15) { // Over 15 minutes late
                  // Track late arrivals for this user
                  if (!lateArrivalsCount[userId]) {
                    lateArrivalsCount[userId] = {
                      count: 0,
                      userName
                    };
                  }
                  lateArrivalsCount[userId].count++;
                  
                  alerts.push({
                    id: `late-${record.id}`,
                    type: 'Late Check-in',
                    severity: minutesLate > 60 ? 'error' : 'warning',
                    user: userName,
                    userId,
                    time: moment(checkInTime).format('MMM D, h:mm A'),
                    timestamp: checkInTime,
                    message: `${userName} checked in ${minutesLate} minutes late on ${moment(checkInTime).format('MMM D')}. Scheduled: ${slot.startTime}, Actual: ${moment(checkInTime).format('h:mm A')}.`,
                    actionRequired: minutesLate > 30, // Only require action for significant lateness
                    minutes: minutesLate
                  });
                }
              }
              
              // 2. Check for missing check-outs
              if (record.checkInTime && !record.checkOutTime) {
                const checkInTime = record.checkInTime.toDate();
                const hoursActive = (now - checkInTime) / (1000 * 60 * 60);
                
                if (dateKey !== today && hoursActive > 12) {
                  // Missing check-out from a previous day
                  alerts.push({
                    id: `missing-checkout-${record.id}`,
                    type: 'Missing Check-out',
                    severity: 'error',
                    user: userName,
                    userId,
                    time: moment(checkInTime).format('MMM D, h:mm A'),
                    timestamp: checkInTime,
                    message: `${userName} checked in on ${moment(checkInTime).format('MMM D')} at ${moment(checkInTime).format('h:mm A')} but never checked out.`,
                    actionRequired: true,
                    attendanceId: record.id
                  });
                } else if (dateKey === today) {
                  // For today, only alert if it's past the scheduled end time
                  if (record.slotIndex !== undefined && timeSlots[record.slotIndex]) {
                    const slot = timeSlots[record.slotIndex];
                    const endTimeParts = slot.endTime.split(':');
                    const scheduledEnd = new Date(scheduleDate);
                    scheduledEnd.setHours(parseInt(endTimeParts[0]), parseInt(endTimeParts[1]), 0, 0);
                    
                    if (now > scheduledEnd && now.getTime() - scheduledEnd.getTime() > 30 * 60 * 1000) { // 30 min grace period
                      alerts.push({
                        id: `open-checkout-${record.id}`,
                        type: 'Missing Check-out',
                        severity: 'warning',
                        user: userName,
                        userId,
                        time: `Today - ${slot.endTime}`,
                        timestamp: now,
                        message: `${userName} was scheduled to end at ${slot.endTime} today but has not checked out yet.`,
                        actionRequired: true,
                        attendanceId: record.id
                      });
                    }
                  }
                }
              }
              
              // 3. Check for early check-outs
              if (record.checkInTime && record.checkOutTime && record.slotIndex !== undefined && timeSlots[record.slotIndex]) {
                const slot = timeSlots[record.slotIndex];
                const endTimeParts = slot.endTime.split(':');
                const scheduledEnd = new Date(scheduleDate);
                scheduledEnd.setHours(parseInt(endTimeParts[0]), parseInt(endTimeParts[1]), 0, 0);
                
                const checkOutTime = record.checkOutTime.toDate();
                const minutesEarly = Math.round((scheduledEnd - checkOutTime) / (1000 * 60));
                
                if (minutesEarly > 15) { // Left over 15 minutes early
                  alerts.push({
                    id: `early-${record.id}`,
                    type: 'Early Check-out',
                    severity: minutesEarly > 60 ? 'warning' : 'info',
                    user: userName,
                    userId,
                    time: moment(checkOutTime).format('MMM D, h:mm A'),
                    timestamp: checkOutTime,
                    message: `${userName} checked out ${minutesEarly} minutes early on ${moment(checkOutTime).format('MMM D')}. Scheduled: ${slot.endTime}, Actual: ${moment(checkOutTime).format('h:mm A')}.`,
                    actionRequired: minutesEarly > 60, // Only require action for significant early departure
                    minutes: minutesEarly
                  });
                }
              }
              
              // 4. Check for long lunch breaks
              if (record.lunchStartTime && record.lunchEndTime) {
                const lunchStart = record.lunchStartTime.toDate();
                const lunchEnd = record.lunchEndTime.toDate();
                const lunchDuration = (lunchEnd - lunchStart) / (1000 * 60);
                
                if (lunchDuration > 60) { // Over 60 minute lunch
                  alerts.push({
                    id: `long-lunch-${record.id}`,
                    type: 'Long Break',
                    severity: lunchDuration > 90 ? 'warning' : 'info',
                    user: userName,
                    userId,
                    time: moment(lunchStart).format('MMM D, h:mm A'),
                    timestamp: lunchStart,
                    message: `${userName} took a ${Math.round(lunchDuration)} minute lunch break on ${moment(lunchStart).format('MMM D')} (standard is 60 minutes).`,
                    actionRequired: lunchDuration > 90, // Only require action for very long breaks
                    minutes: Math.round(lunchDuration)
                  });
                }
              }
            });
          }
        }
        
        // Add alerts for consistent late arrivals
        Object.entries(lateArrivalsCount).forEach(([userId, data]) => {
          if (data.count >= 3) {
            alerts.push({
              id: `consistent-late-${userId}`,
              type: 'Consistent Late Arrivals',
              severity: 'error',
              user: data.userName,
              userId,
              time: moment().format('MMM D'),
              timestamp: new Date(),
              message: `${data.userName} has been late ${data.count} times in the last ${this.alertFilter.period === 'today' ? 'day' : this.alertFilter.period === 'week' ? 'week' : 'month'}.`,
              actionRequired: true,
              count: data.count
            });
          }
        });
        
        // Sort alerts by timestamp (newest first) and severity
        alerts.sort((a, b) => {
          // First by severity
          const severityOrder = { error: 0, warning: 1, info: 2 };
          const severityDiff = severityOrder[a.severity] - severityOrder[b.severity];
          if (severityDiff !== 0) return severityDiff;
          
          // Then by actionRequired (required actions first)
          if (a.actionRequired && !b.actionRequired) return -1;
          if (!a.actionRequired && b.actionRequired) return 1;
          
          // Then by timestamp (newest first)
          return b.timestamp - a.timestamp;
        });
        
        console.log(`Generated ${alerts.length} alerts`);
        this.alerts = alerts;
      } catch (error) {
        console.error('Error fetching alerts:', error);
        this.$emit('error', 'Failed to load alerts');
      } finally {
        this.loading.alerts = false;
      }
    },
    
    resolveAlert(alert) {
      this.resolvedAlerts.add(alert.id);
      console.log(`Alert resolved: ${alert.id}`);
      
      if (alert.type === 'Missing Check-out') {
        console.log(`Auto check-out for user ${alert.userId} could be triggered here`);
      }
    },
    
    contactUser(alert) {
      alert(`Contact functionality for ${alert.user} would open here`);
    },
    
    formatHours(hours) {
      if (!hours) return '0:00';
      
      const wholeHours = Math.floor(hours);
      const minutes = Math.round((hours - wholeHours) * 60);
      
      return `${wholeHours}:${minutes.toString().padStart(2, '0')}`;
    },
    
    getStatusColor(status) {
      if (status === 'Working') return 'success';
      if (status === 'On Lunch') return 'warning';
      if (status === 'Not Checked In') return 'error';
      if (status === 'Checked Out' || status.includes('Completed')) return 'info';
      return '';
    },
    
    getReportHeaders() {
      return [
        { text: 'Fecha', value: 'date' },
        { text: 'Empleado', value: 'userName' },
        { text: 'Entrada', value: 'checkIn' },
        { text: 'Salida', value: 'checkOut' },
        { text: 'Turno', value: 'scheduledShift' },
        { text: 'Horas', value: 'totalHours' },
        { text: 'Duración Descanso', value: 'lunchDuration' },
        { text: 'Cumplimiento', value: 'adherence' }
      ];
    },
    
    getReportRowClass(item) {
      if (item.recordType === 'no-show') {
        return 'no-show-row';
      }
      return '';
    },
    
    getAlertColor(severity) {
      if (severity === 'error') return 'error';
      if (severity === 'warning') return 'warning';
      if (severity === 'info') return 'info';
      return '';
    },
    
    getAlertChipColor(severity) {
      if (severity === 'error') return 'error';
      if (severity === 'warning') return 'warning';
      if (severity === 'info') return 'info';
      return '';
    },
    
    getAlertSeverityText(severity) {
      if (severity === 'error') return 'Crítico';
      if (severity === 'warning') return 'Advertencia';
      if (severity === 'info') return 'Info';
      return '';
    },
    
    getMetricColor(minutes) {
      if (minutes > 60) return 'error';
      if (minutes > 30) return 'warning';
      return 'info';
    },
    
    async forceCheckout(alert) {
      if (!confirm(`¿Está seguro que desea forzar la salida de ${alert.user}?`)) {
        return;
      }
      
      try {
        // Get the attendance record
        const attendanceRef = doc(db, 'attendance', alert.attendanceId);
        
        // Update the record with the checkout time
        await updateDoc(attendanceRef, {
          checkOutTime: serverTimestamp(),
          status: 'Checked Out',
          totalWorkHours: 8, // Default to 8 hours for forced checkout
          adminForced: true,
          adminForcedAt: serverTimestamp()
        });
        
        // Mark the alert as resolved
        this.resolvedAlerts.add(alert.id);
        
        this.$emit('success', `Forced checkout completed for ${alert.user}`);
      } catch (error) {
        console.error('Error forcing checkout:', error);
        this.$emit('error', 'Failed to force checkout');
      }
    }
  },
  watch: {
    'alertFilter.type'() {
      // No need to refetch, just let the computed property filter
    },
    'alertFilter.period'() {
      this.fetchAlerts();
    }
  },
  async mounted() {
    await this.fetchUsers();  // Wait for users to be loaded first
    this.fetchAlerts();      // Then fetch alerts
  }
}
</script>

<style scoped>
.admin-panel-container {
  width: 100%;
}

.panel-tabs {
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 20px;
}

.panel-tabs button {
  padding: 10px 20px;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: bold;
  color: #757575;
}

.panel-tabs button.active {
  color: #2196F3;
  border-bottom: 2px solid #2196F3;
}

.search-bar {
  margin-bottom: 20px;
}

.search-bar input {
  width: 100%;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 16px;
}

.users-table {
  width: 100%;
  border-collapse: collapse;
}

.users-table th, .users-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.users-table th {
  background-color: #f5f5f5;
  font-weight: bold;
}

.status-working {
  color: #4CAF50;
  font-weight: bold;
}

.status-on.lunch {
  color: #FF9800;
  font-weight: bold;
}

.status-checked.out {
  color: #9E9E9E;
}

.status-not.checked.in {
  color: #F44336;
}

.action-btn {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  margin-right: 5px;
  cursor: pointer;
}

.view-btn {
  background-color: #2196F3;
  color: white;
}

.edit-btn {
  background-color: #FF9800;
  color: white;
}

.schedule-editor {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
}

.day-selector {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 20px;
}

.time-slots {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
}

.time-slot {
  display: flex;
  gap: 15px;
  align-items: center;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 4px;
}

.time-field, .date-field {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.time-field label, .date-field label {
  font-weight: bold;
}

.time-field input, .date-field input {
  padding: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.schedule-actions {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.apply-btn {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-btn {
  padding: 10px 20px;
  background-color: #F44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.report-filters, .alert-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 8px;
}

.filter-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.filter-group label {
  font-weight: bold;
}

.filter-group select, .filter-group input {
  padding: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.generate-btn {
  padding: 8px 16px;
  background-color: #2196F3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.report-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.report-table th, .report-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.report-table th {
  background-color: #f5f5f5;
  font-weight: bold;
}

.adherence-good {
  color: #4CAF50;
}

.adherence-fair {
  color: #FF9800;
}

.adherence-poor {
  color: #F44336;
}

.report-actions {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.download-btn, .print-btn {
  padding: 10px 20px;
  background-color: #2196F3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.print-btn {
  background-color: #9E9E9E;
}

.alerts-list {
  margin-top: 20px;
}

.alert-item {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  border-left: 5px solid #9E9E9E;
  transition: all 0.2s ease;
}

.alert-item.resolved {
  opacity: 0.6;
  background-color: #f0f0f0;
}

.severity-error {
  border-left-color: #F44336;
  border-top: 1px solid #FFCDD2;
  border-bottom: 1px solid #FFCDD2;
  border-right: 1px solid #FFCDD2;
}

.severity-warning {
  border-left-color: #FF9800;
  border-top: 1px solid #FFE0B2;
  border-bottom: 1px solid #FFE0B2;
  border-right: 1px solid #FFE0B2;
}

.severity-info {
  border-left-color: #2196F3;
  border-top: 1px solid #BBDEFB;
  border-bottom: 1px solid #BBDEFB;
  border-right: 1px solid #BBDEFB;
}

.alert-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.alert-type-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.alert-type {
  font-weight: bold;
}

.alert-badge {
  font-size: 0.8em;
  padding: 3px 8px;
  border-radius: 12px;
  font-weight: bold;
}

.alert-badge.error {
  background-color: #FFEBEE;
  color: #D32F2F;
}

.alert-badge.warning {
  background-color: #FFF3E0;
  color: #EF6C00;
}

.alert-badge.info {
  background-color: #E3F2FD;
  color: #1976D2;
}

.alert-metrics {
  background-color: #f5f5f5;
  padding: 8px 12px;
  border-radius: 6px;
  margin-top: 10px;
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.metric-label {
  font-size: 0.9em;
  color: #757575;
}

.metric-value {
  font-weight: bold;
  font-size: 1.1em;
}

.metric-value.critical {
  color: #D32F2F;
}

.metric-value.warning {
  color: #EF6C00;
}

.metric-value.minor {
  color: #1976D2;
}

.resolve-btn {
  padding: 8px 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.resolve-btn:hover {
  background-color: #388E3C;
}

.resolved-btn {
  background-color: #9E9E9E;
}

.resolved-btn:hover {
  background-color: #757575;
}

.action-btn {
  padding: 8px 16px;
  background-color: #673AB7;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.action-btn:hover {
  background-color: #5E35B1;
}

.no-alerts {
  text-align: center;
  padding: 20px;
  color: #757575;
}

.add-slot-btn {
  align-self: flex-start;
  background-color: #2196F3;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.remove-slot-btn {
  background-color: #FF5252;
  color: white;
  border: none;
  border-radius: 4px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.status-working {
  color: #4CAF50;
  font-weight: bold;
}

.status-lunch {
  color: #FF9800;
  font-weight: bold;
}

.status-not-checked-in {
  color: #9E9E9E;
}

.status-checked-out {
  color: #2196F3;
}

.summary-row {
  background-color: #EDE7F6;
  font-weight: bold;
  border-top: 1px solid #D1C4E9;
  border-bottom: 1px solid #D1C4E9;
}

.total-hours {
  font-weight: bold;
  color: #2196F3;
}

.shift-number {
  font-size: 0.8em;
  color: #757575;
  margin-left: 5px;
}

.no-show-row {
  background-color: #FFF8E1;
  color: #FF8F00;
}

.error {
  color: #F44336;
}

.border-left-error {
  border-left: 4px solid #F44336;
  background-color: unset !important;
}

.good.border-left-error {
  border-left: 4px solid #4CAF50;
  background-color: unset !important;
}

.warning {
  color: #FF9800;
}

.good {
  color: #4CAF50;
}

.adherence-details {
  font-size: 0.8em;
  margin-top: 5px;
  color: #757575;
}
</style> 